import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { updateFaive } from '../services/faiveService';
import styles from './FaiveForm.module.css';

const categories = ['Movie/TV', 'Book', 'Food', 'Music', 'Miscellaneous'];

/**
 * @typedef {Object} Faive
 * @property {string} id
 * @property {string} title
 * @property {string} description
 * @property {string} category
 * @property {string} [hyperlink]
 * @property {string} visibility
 */

/**
 * @param {Object} props
 * @param {Faive} props.faive
 * @param {() => void} props.onClose
 * @param {() => void} props.onUpdate
 */
const EditFaive = ({ faive, onClose, onUpdate }) => {
  const [title, setTitle] = useState(faive.title);
  const [description, setDescription] = useState(faive.description);
  const [category, setCategory] = useState(faive.category);
  const [hyperlink, setHyperlink] = useState(faive.hyperlink || '');
  const [visibility, setVisibility] = useState(faive.visibility);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      await updateFaive(faive.id, {
        title,
        description,
        category,
        hyperlink,
        visibility,
      });
      onUpdate();
      onClose();
    } catch (err) {
      console.error("Error updating faive:", err);
      setError("Failed to update faive. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.compactForm}>
      <div className={styles.formGroup}>
        <label htmlFor="category">Category:</label>
        <select
          id="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className={styles.select}
        >
          {categories.map(cat => (
            <option key={cat} value={cat}>{cat}</option>
          ))}
        </select>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="title">Title:</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          className={styles.input}
        />
      </div>

      <div className={`${styles.formGroup} ${styles.fullWidth}`}>
        <label htmlFor="description">Description:</label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          className={styles.textarea}
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="hyperlink">Hyperlink:</label>
        <input
          type="url"
          id="hyperlink"
          value={hyperlink}
          onChange={(e) => setHyperlink(e.target.value)}
          className={styles.input}
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="visibility">Visibility:</label>
        <select
          id="visibility"
          value={visibility}
          onChange={(e) => setVisibility(e.target.value)}
          className={styles.select}
        >
          <option value="public">Public</option>
          <option value="friends">Friends Only</option>
          <option value="private">Private</option>
        </select>
      </div>

      {error && <p className={styles.error}>{error}</p>}

      <div className={styles.buttonGroup}>
        <button type="submit" className={styles.submitButton}>Update Faive</button>
        <button type="button" onClick={(e) => { e.preventDefault(); onClose(); }} className={styles.cancelButton}>Cancel</button>
      </div>
    </form>
  );
};

EditFaive.propTypes = {
  faive: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    hyperlink: PropTypes.string,
    visibility: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default EditFaive;