export const logger = {
    log: (...args) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log(...args);
      }
    },
    warn: (...args) => {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(...args);
      }
    },
    error: (...args) => {
      // We often want to keep error logs even in production
      console.error(...args);
    }
  };