import { collection, addDoc, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { getFriends } from './relationshipService';
import { getUserProfile } from './userService';

export const createActivity = async (userId, action, category, itemId) => {
  try {
    const activityData = {
      userId,
      action,
      category,
      itemId,
      timestamp: new Date()
    };
    await addDoc(collection(db, 'activities'), activityData);
  } catch (error) {
    console.error('Error creating activity:', error);
    throw error;
  }
};

export const getFriendActivity = async (userId, limitCount = 10) => {
  console.log(`Getting friend activity for user: ${userId}`);
  try {
    const friendIds = await getFriends(userId);
    console.log(`Friend IDs: ${friendIds.join(', ')}`);

    // Remove the current user's ID from the query
    const userIds = [...friendIds];

    const activitiesRef = collection(db, 'activities');
    const q = query(
      activitiesRef,
      where('userId', 'in', userIds),
      orderBy('timestamp', 'desc'),
      limit(limitCount)
    );

    const querySnapshot = await getDocs(q);
    const activities = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    console.log(`Found ${activities.length} activities`);

    const activitiesWithUserData = await Promise.all(activities.map(async (activity) => {
      const userProfile = await getUserProfile(activity.userId);
      return {
        ...activity,
        user: userProfile
      };
    }));

    console.log(`Processed ${activitiesWithUserData.length} activities with user data`);
    return activitiesWithUserData;
  } catch (error) {
    console.error('Error fetching friend activities:', error);
    throw error;
  }
};