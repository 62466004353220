import React from 'react';
import PropTypes from 'prop-types';
import styles from './Container.module.css';

function Container({ children, title }) {
  return (
    <div className={styles.container}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Container;