import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getFriendActivity } from '../services/activityService';
import { useAuth } from '../context/AuthContext';
import styles from './FriendActivity.module.css';
import { FaUser } from 'react-icons/fa';

const FriendActivity = () => {
  const [activities, setActivities] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchActivities = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        console.log("Fetching friend activities for user:", currentUser.uid);
        const recentActivities = await getFriendActivity(currentUser.uid);
        console.log("Recent activities:", recentActivities);
        setActivities(recentActivities);
      } catch (error) {
        console.error('Error fetching friend activities:', error);
        setError('Failed to load friend activities. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, [currentUser]);

  if (!currentUser) {
    return <div>Please log in to see friend activity.</div>;
  }

  if (loading) {
    return <div>Loading friend activities...</div>;
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <div className={styles.friendActivity}>
      <h3 className={styles.title}>Friend Activity</h3>
      {activities.length === 0 ? (
        <p className={styles.emptyMessage}>No recent friend activity. Add more friends to see their activities!</p>
      ) : (
        <ul className={styles.activityList}>
          {activities.map(activity => (
            <li key={activity.id} className={styles.activityItem}>
              <Link to={`/profile/${activity.user?.username}`} className={styles.userLink}>
                {activity.user?.photoURL ? (
                  <img src={activity.user.photoURL} alt={activity.user?.username} className={styles.userPhoto} />
                ) : (
                  <div className={styles.userPhotoPlaceholder}>
                    <FaUser />
                  </div>
                )}
                <span className={styles.username}>{activity.user?.username || 'A user'}</span>
              </Link>
              <span className={styles.activityDescription}>
                {activity.action} a new faive in {activity.category}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FriendActivity;