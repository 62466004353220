import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db, auth } from '../firebase';
import styles from './FeedbackForm.module.css';

const FeedbackForm = () => {
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!feedback.trim()) return;

    setIsSubmitting(true);
    try {
      await addDoc(collection(db, 'feedback'), {
        message: feedback,
        createdAt: new Date(),
        userId: auth.currentUser ? auth.currentUser.uid : 'anonymous'
      });
      setFeedback('');
      setSubmitMessage('Thank you for your feedback!');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setSubmitMessage('Error submitting feedback. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <h2>We'd love to hear your feedback!</h2>
      <textarea
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        placeholder="Share your thoughts, suggestions, or report issues..."
        className={styles.textarea}
        required
      />
      <button type="submit" disabled={isSubmitting} className={styles.submitButton}>
        {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
      </button>
      {submitMessage && <p className={styles.message}>{submitMessage}</p>}
    </form>
  );
};

export default FeedbackForm;