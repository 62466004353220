// src/services/relationshipService.js

import { doc, setDoc, updateDoc, deleteDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

// Helper function
const createRelationshipDoc = async (userId, targetId, type, status) => {
  const ref = doc(db, 'relationships', userId, type, targetId);
  await setDoc(ref, {
    type,
    status,
    createdAt: new Date(),
    updatedAt: new Date()
  });
};

// Following functions
export const followUser = async (followerId, followedId) => {
  try {
    await createRelationshipDoc(followerId, followedId, 'following', 'active');
    console.log(`User ${followerId} successfully followed user ${followedId}`);
  } catch (error) {
    console.error(`Error following user: ${followerId} -> ${followedId}`, error);
    throw error;
  }
};

export const unfollowUser = async (followerId, followedId) => {
  try {
    const ref = doc(db, 'relationships', followerId, 'following', followedId);
    await deleteDoc(ref);
    console.log(`User ${followerId} successfully unfollowed user ${followedId}`);
  } catch (error) {
    console.error(`Error unfollowing user: ${followerId} -> ${followedId}`, error);
    throw error;
  }
};

// Friend request functions
export const sendFriendRequest = async (senderId, receiverId) => {
  try {
    const timestamp = new Date();
    await Promise.all([
      setDoc(doc(db, 'relationships', senderId, 'friends', receiverId), {
        type: 'friends',
        status: 'pending_sent',
        createdAt: timestamp,
        updatedAt: timestamp
      }),
      setDoc(doc(db, 'relationships', receiverId, 'friends', senderId), {
        type: 'friends',
        status: 'pending_received',
        createdAt: timestamp,
        updatedAt: timestamp
      })
    ]);
    console.log(`Friend request sent successfully: ${senderId} -> ${receiverId}`);
  } catch (error) {
    console.error(`Error sending friend request: ${senderId} -> ${receiverId}`, error);
    throw error;
  }
};

export const acceptFriendRequest = async (userId, friendId) => {
  try {
    const timestamp = new Date();
    const updateData = {
      status: 'active',
      updatedAt: timestamp,
      acceptedAt: timestamp
    };
    await Promise.all([
      updateDoc(doc(db, 'relationships', userId, 'friends', friendId), updateData),
      updateDoc(doc(db, 'relationships', friendId, 'friends', userId), updateData)
    ]);
    console.log(`Friend request accepted: ${userId} <-> ${friendId}`);
  } catch (error) {
    console.error(`Error accepting friend request: ${userId} <-> ${friendId}`, error);
    throw error;
  }
};

export const rejectFriendRequest = async (userId, friendId) => {
  try {
    await Promise.all([
      deleteDoc(doc(db, 'relationships', userId, 'friends', friendId)),
      deleteDoc(doc(db, 'relationships', friendId, 'friends', userId))
    ]);
    console.log(`Friend request rejected: ${userId} x ${friendId}`);
  } catch (error) {
    console.error(`Error rejecting friend request: ${userId} x ${friendId}`, error);
    throw error;
  }
};

export const removeFriend = async (userId, friendId) => {
  try {
    await Promise.all([
      deleteDoc(doc(db, 'relationships', userId, 'friends', friendId)),
      deleteDoc(doc(db, 'relationships', friendId, 'friends', userId))
    ]);
    console.log(`Friend removed: ${userId} x ${friendId}`);
  } catch (error) {
    console.error(`Error removing friend: ${userId} x ${friendId}`, error);
    throw error;
  }
};

// Get relationship functions
const getRelationships = async (userId, type, status) => {
  try {
    const ref = collection(db, 'relationships', userId, type);
    const q = query(ref, where('status', '==', status));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => doc.id);
  } catch (error) {
    console.error(`Error getting ${type} for user: ${userId}`, error);
    throw error;
  }
};

export const getFollowing = async (userId) => {
  return getRelationships(userId, 'following', 'active');
};

export const getFriends = async (userId) => {
  console.log(`Getting friends for user: ${userId}`);
  try {
    const ref = collection(db, 'relationships', userId, 'friends');
    const q = query(ref, where('status', '==', 'active'));
    const querySnapshot = await getDocs(q);
    const friends = querySnapshot.docs.map(doc => doc.id);
    console.log(`Found ${friends.length} friends for user: ${userId}`);
    return friends;
  } catch (error) {
    console.error(`Error getting friends for user: ${userId}`, error);
    throw error;
  }
};

export const getFriendRequests = async (userId) => {
  try {
    const ref = collection(db, 'relationships', userId, 'friends');
    const q = query(ref, where('status', '==', 'pending_received'));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      senderId: doc.id, // Assuming the document ID is the sender's ID
      ...doc.data()
    }));
  } catch (error) {
    console.error(`Error getting friend requests for user: ${userId}`, error);
    throw error;
  }
};