import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { getFriends, sendFriendRequest, getFriendRequests, acceptFriendRequest } from '../services/relationshipService';
import { getUserProfile, getUserIdFromEmailOrUsername } from '../services/userService';
import BrowseUsers from '../components/BrowseUsers';
import styles from './FriendsPage.module.css';
import { FaUser, FaUserPlus } from 'react-icons/fa';

const FriendsPage = () => {
  const { currentUser } = useAuth();
  const [friends, setFriends] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFriendsAndRequests = async () => {
      if (currentUser) {
        try {
          setLoading(true);
          setError('');

          // Fetch friends
          const friendIds = await getFriends(currentUser.uid);
          const friendData = await Promise.all(friendIds.map(async (friendId) => {
            try {
              return await getUserProfile(friendId);
            } catch (error) {
              console.error(`Error fetching profile for user ${friendId}:`, error);
              return null;
            }
          }));
          setFriends(friendData.filter(Boolean));

          // Fetch friend requests
          const requests = await getFriendRequests(currentUser.uid);
          const requestData = await Promise.all(requests.map(async (request) => {
            try {
              const profile = await getUserProfile(request.senderId);
              return profile ? { ...request, profile } : null;
            } catch (error) {
              console.error(`Error fetching profile for request ${request.senderId}:`, error);
              return null;
            }
          }));
          setFriendRequests(requestData.filter(Boolean));
        } catch (error) {
          console.error("Error fetching friends and requests:", error);
          setError("Failed to load friends and requests. Please try again later.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchFriendsAndRequests();
  }, [currentUser]);

  const handleSendFriendRequest = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const friendId = await getUserIdFromEmailOrUsername(searchTerm);
      await sendFriendRequest(currentUser.uid, friendId);
      setSearchTerm('');
      setSuccess('Friend request sent successfully!');
    } catch (error) {
      setError('Failed to send friend request: ' + error.message);
    }
  };

  const handleAcceptFriendRequest = async (friendId) => {
    try {
      await acceptFriendRequest(currentUser.uid, friendId);
      const updatedFriends = await getFriends(currentUser.uid);
      const updatedFriendData = await Promise.all(updatedFriends.map(id => getUserProfile(id)));
      setFriends(updatedFriendData.filter(Boolean));
      setFriendRequests(friendRequests.filter(request => request.senderId !== friendId));
    } catch (error) {
      setError('Failed to accept friend request: ' + error.message);
    }
  };

  if (loading) {
    return <div className={styles.loading}>Loading friends and requests...</div>;
  }

  return (
    <div className={styles.friendsPage}>
      <h1 className={styles.pageTitle}>Friends</h1>
      
      <div className={styles.friendRequests}>
        <h2 className={styles.sectionTitle}>Friend Requests</h2>
        {friendRequests.length === 0 ? (
          <p className={styles.emptyMessage}>No pending friend requests.</p>
        ) : (
          <div className={styles.requestList}>
            {friendRequests.map(request => (
              <div key={request.senderId} className={styles.requestItem}>
                <Link to={`/profile/${request.profile?.username}`} className={styles.userLink}>
                  {request.profile?.photoURL ? (
                    <img src={request.profile.photoURL} alt={request.profile?.username} className={styles.userPhoto} />
                  ) : (
                    <div className={styles.userPhotoPlaceholder}>
                      <FaUser />
                    </div>
                  )}
                  <span className={styles.username}>{request.profile?.username}</span>
                </Link>
                <button onClick={() => handleAcceptFriendRequest(request.senderId)} className={styles.acceptButton}>
                  Accept
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={styles.addFriend}>
        <h2 className={styles.sectionTitle}>Add a Friend</h2>
        <form onSubmit={handleSendFriendRequest} className={styles.addFriendForm}>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Enter friend's email or username"
            required
            className={styles.addFriendInput}
          />
          <button type="submit" className={styles.addFriendButton}>
            <FaUserPlus /> Send Request
          </button>
        </form>
      </div>

      {error && <p className={styles.error}>{error}</p>}
      {success && <p className={styles.success}>{success}</p>}

      <div className={styles.friendsList}>
        <h2 className={styles.sectionTitle}>Your Friends</h2>
        {friends.length === 0 ? (
          <p className={styles.emptyMessage}>You don't have any friends yet.</p>
        ) : (
          <div className={styles.friendsGrid}>
            {friends.map(friend => (
              <Link key={friend.uid} to={`/profile/${friend.username}`} className={styles.friendItem}>
                {friend.photoURL ? (
                  <img src={friend.photoURL} alt={friend.username} className={styles.friendPhoto} />
                ) : (
                  <div className={styles.friendPhotoPlaceholder}>
                    <FaUser />
                  </div>
                )}
                <span className={styles.friendName}>{friend.username}</span>
              </Link>
            ))}
          </div>
        )}
      </div>

      <div className={styles.browseUsersSection}>
        <h2 className={styles.sectionTitle}>Discover New Friends</h2>
        <BrowseUsers />
      </div>
    </div>
  );
};

export default FriendsPage;