// src/pages/UserProfilePage.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { auth, storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Container from '../components/Container';
import FaiveCard from '../components/FaiveCard';
import FaiveForm from '../components/FaiveForm';
import { updateUserProfile, getUserProfile, getUserByUsername } from '../services/userService';
import { getFaives } from '../services/faiveService';
import { followUser, unfollowUser, sendFriendRequest, getFriends, getFollowing } from '../services/relationshipService';
import styles from './UserProfilePage.module.css';
import { FaUser } from 'react-icons/fa';

function UserProfilePage() {
  const { username } = useParams();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [faives, setFaives] = useState([]);
  const [friends, setFriends] = useState([]);
  const [following, setFollowing] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isFriend, setIsFriend] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isAddingFaive, setIsAddingFaive] = useState(false);
  const [isEditingBio, setIsEditingBio] = useState(false);
  const [editedBio, setEditedBio] = useState('');
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const fetchProfileData = async () => {
    console.log("Fetching profile data for:", username || "current user");
    try {
      let profileData;
      let profileUserId;
  
      if (username) {
        profileData = await getUserByUsername(username);
        console.log("Profile data fetched by username:", profileData);
        if (!profileData) {
          throw new Error('User not found');
        }
        profileUserId = profileData.uid;
      } else if (auth.currentUser) {
        profileUserId = auth.currentUser.uid;
        profileData = await getUserProfile(profileUserId);
        console.log("Profile data fetched for current user:", profileData);
      } else {
        throw new Error('No user logged in');
      }
  
      console.log("Setting profile state with:", profileData);
      setProfile(profileData);
      setEditedBio(profileData.bio || '');

      console.log("Profile data set:", profileData);
  
      const userFaives = await getFaives(profileUserId);
      console.log("User faives fetched:", userFaives.length);
      setFaives(userFaives);
      
      const friendIds = await getFriends(profileUserId);
      console.log("Friend IDs fetched:", friendIds.length);
      const friendsData = await Promise.all(friendIds.map(async (friendId) => {
        const friendProfile = await getUserProfile(friendId);
        return { id: friendId, ...friendProfile };
      }));
      console.log("Friends data fetched:", friendsData.length);
      setFriends(friendsData);

      if (auth.currentUser && auth.currentUser.uid !== profileUserId) {
        const userFollowing = await getFollowing(auth.currentUser.uid);
        setFollowing(userFollowing);
        setIsFollowing(userFollowing.includes(profileUserId));
        setIsFriend(friendIds.includes(auth.currentUser.uid));
      }

      setLoading(false);
    } catch (err) {
      console.error("Error fetching profile data:", err);
      setError(err.message || 'Failed to load profile data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, [username, auth.currentUser]);

  useEffect(() => {
    console.log("Profile state updated:", profile);
  }, [profile]);

  const handleFaiveAdded = () => {
    fetchProfileData();
    setIsAddingFaive(false);
  };

  const handleSaveBio = async () => {
    try {
      await updateUserProfile(auth.currentUser.uid, { bio: editedBio });
      setProfile(prevProfile => prevProfile ? { ...prevProfile, bio: editedBio } : null);
      console.log("Bio updated, new profile state:", profile);
      setIsEditingBio(false);
    } catch (error) {
      console.error("Error updating bio:", error);
      setError('Failed to update bio. Please try again.');
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      handleUpload(e.target.files[0]);
    }
  };

  const handleUpload = async (file) => {
    if (!file) return;
    setUploading(true);
    try {
      const storageRef = ref(storage, `profile_photos/${auth.currentUser.uid}`);
      await uploadBytes(storageRef, file);
      const photoURL = await getDownloadURL(storageRef);
      await updateUserProfile(auth.currentUser.uid, { photoURL });
      setProfile(prevProfile => prevProfile ? { ...prevProfile, photoURL } : null);
    } catch (error) {
      console.error("Error uploading photo:", error);
      setError('Failed to upload photo. Please try again.');
    } finally {
      setUploading(false);
      setFile(null);
    }
  };

  const handleFollow = async () => {
    try {
      if (profile) {
        if (isFollowing) {
          await unfollowUser(auth.currentUser.uid, profile.uid);
          setIsFollowing(false);
        } else {
          await followUser(auth.currentUser.uid, profile.uid);
          setIsFollowing(true);
        }
      }
    } catch (error) {
      console.error("Error updating follow status:", error);
      setError('Failed to update follow status. Please try again.');
    }
  };

  const handleFriendRequest = async () => {
    try {
      if (profile) {
        await sendFriendRequest(auth.currentUser.uid, profile.uid);
        setSuccessMessage('Friend request sent successfully!');
        setError('');
      }
    } catch (error) {
      console.error("Error sending friend request:", error);
      setError('Failed to send friend request. Please try again.');
      setSuccessMessage('');
    }
  };

  const isOwnProfile = !username || (auth.currentUser && profile && auth.currentUser.uid === profile.uid);

  if (loading) return <Container title="User Profile">Loading...</Container>;
  if (error) return <Container title="User Profile">Error: {error}</Container>;

  return (
    <Container title="User Profile">
      {loading ? (
        <p>Loading profile...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : profile ? (
        <div className={styles.profileContainer}>
          <div className={styles.profileHeader}>
            <div className={styles.photoUploadArea}>
              {profile.photoURL ? (
                <img 
                  src={profile.photoURL} 
                  alt={`${profile.username}'s profile`} 
                  className={styles.profilePhoto} 
                />
              ) : (
                <div className={styles.profilePhotoPlaceholder}>
                  <FaUser />
                </div>
              )}
              {isOwnProfile && (
                <div className={styles.photoUploadOverlay}>
                  <input 
                    type="file" 
                    onChange={handleFileChange} 
                    accept="image/*" 
                    id="photoUpload" 
                    className={styles.photoUploadInput} 
                  />
                  <label htmlFor="photoUpload" className={styles.photoUploadLabel}>
                    {uploading ? 'Uploading...' : 'Change Photo'}
                  </label>
                </div>
              )}
            </div>
            <div className={styles.profileInfo}>
              <h2>{profile.username}</h2>
              {isOwnProfile ? (
                isEditingBio ? (
                  <div>
                    <textarea
                      value={editedBio}
                      onChange={(e) => setEditedBio(e.target.value)}
                      className={styles.bioTextarea}
                      placeholder="Tell us about yourself"
                    />
                    <button onClick={handleSaveBio} className={styles.button}>Save Bio</button>
                    <button onClick={() => setIsEditingBio(false)} className={styles.button}>Cancel</button>
                  </div>
                ) : (
                  <div>
                    <p className={styles.bio}>{profile.bio || 'No bio yet.'}</p>
                    <button onClick={() => setIsEditingBio(true)} className={styles.button}>Edit Bio</button>
                  </div>
                )
              ) : (
                <div>
                  <p className={styles.bio}>{profile.bio || 'No bio yet.'}</p>
                  {auth.currentUser && (
                    <div>
                      <button onClick={handleFollow} className={styles.button}>
                        {isFollowing ? 'Unfollow' : 'Follow'}
                      </button>
                      {!isFriend && (
                        <button onClick={handleFriendRequest} className={styles.button}>
                          Send Friend Request
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {successMessage && <p className={styles.success}>{successMessage}</p>}
          {error && <p className={styles.error}>{error}</p>}
          <div className={styles.profileContent}>
            <div className={styles.faivesSection}>
              <h3>Faives</h3>
              {isOwnProfile && (
                isAddingFaive ? (
                  <div className={styles.addFaiveForm}>
                    <FaiveForm onSuccess={handleFaiveAdded} compact={true} />
                  </div>
                ) : (
                  <button onClick={() => setIsAddingFaive(true)} className={styles.addFaiveButton}>
                    Add New Faive
                  </button>
                )
              )}
              <div className={styles.faivesGrid}>
                {faives.map(faive => (
                  <FaiveCard 
                    key={faive.id} 
                    faive={faive} 
                    onUpdate={fetchProfileData}
                    username={profile.username}
                  />
                ))}
              </div>
            </div>
            <div className={styles.sidebarSection}>
              <h3>Friends</h3>
              <div className={styles.friendsGrid}>
                {friends.map(friend => (
                  <Link key={friend.id} to={`/profile/${friend.username}`} className={styles.friendItem}>
                    {friend.photoURL ? (
                      <img src={friend.photoURL} alt={friend.username} className={styles.friendPhoto} />
                    ) : (
                      <div className={styles.friendPhotoPlaceholder}>
                        <FaUser />
                      </div>
                    )}
                    <span className={styles.friendName}>{friend.username}</span>
                  </Link>
                ))}
              </div>
              {isOwnProfile && (
                <button
                  onClick={() => navigate('/friends')}
                  className={styles.manageFriendsButton}
                >
                  Manage Friends
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <p>No profile data available.</p>
      )}
    </Container>
  );
}

export default UserProfilePage;