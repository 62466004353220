import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './FaiveCard.module.css';
import { auth } from '../firebase';
import { deleteFaiveCompletely } from '../services/faiveService';
import EditFaive from './EditFaive';
import { FaUser, FaChevronDown, FaChevronUp, FaBook, FaMusic, FaFilm, FaUtensils, FaLink } from 'react-icons/fa';

const categoryEmojis = {
  'Movie/TV': '🎬',
  'Book': '📚',
  'Music': '🎵',
  'Food': '🍽️',
  'Miscellaneous': '🌟'
};

const getLinkIcon = (url) => {
  if (url.includes('goodreads.com')) return <FaBook />;
  if (url.includes('spotify.com')) return <FaMusic />;
  if (url.includes('imdb.com')) return <FaFilm />;
  if (url.includes('allrecipes.com')) return <FaUtensils />;
  return <FaLink />;
};

const truncateUrl = (url) => {
  const domain = new URL(url).hostname.replace('www.', '');
  return domain.length > 20 ? domain.substring(0, 20) + '...' : domain;
};

const FaiveCard = ({ faive, onUpdate, username }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [truncatedDescription, setTruncatedDescription] = useState('');

  useEffect(() => {
    if (faive.description.length > 150) {
      setTruncatedDescription(faive.description.slice(0, 150) + '...');
    } else {
      setTruncatedDescription(faive.description);
    }
  }, [faive.description]);

  const formatDate = (dateValue) => {
    if (!dateValue) return 'Date not available';
    
    let date;
    if (typeof dateValue.toDate === 'function') {
      date = dateValue.toDate();
    } else if (dateValue instanceof Date) {
      date = dateValue;
    } else {
      return 'Invalid date';
    }
    
    return date.toLocaleDateString();
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this faive?')) {
      try {
        await deleteFaiveCompletely(auth.currentUser.uid, faive.id);
        onUpdate();
      } catch (err) {
        console.error("Error deleting faive:", err);
        if (err.code === 'permission-denied') {
          setError("You don't have permission to delete this faive.");
        } else {
          setError("Failed to delete faive. Please try again.");
        }
      }
    }
  };

  const handleEditComplete = () => {
    setIsEditing(false);
    onUpdate();
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const isOwner = auth.currentUser && auth.currentUser.uid === faive.userId;

  return (
    <div className={styles.faiveCard}>
      {isEditing ? (
        <EditFaive
          faive={faive}
          onClose={() => setIsEditing(false)}
          onUpdate={handleEditComplete}
        />
      ) : (
        <>
          <div className={styles.cardHeader}>
            <Link to={`/profile/${username}`} className={styles.username}>
              <FaUser className={styles.userIcon} />
              {username}
            </Link>
            <span className={styles.categoryEmoji}>{categoryEmojis[faive.category]}</span>
          </div>
          <div className={styles.cardContent}>
            <h3 className={styles.faiveTitle}>{faive.title}</h3>
            <div className={styles.descriptionContainer}>
              <p className={styles.faiveDescription}>
                {isExpanded ? faive.description : truncatedDescription}
              </p>
              {faive.description.length > 150 && (
                <button onClick={toggleExpand} className={styles.expandButton}>
                  {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
                  <span>{isExpanded ? 'Show less' : 'Show more'}</span>
                </button>
              )}
            </div>
            {faive.hyperlink && (
              <a href={faive.hyperlink} target="_blank" rel="noopener noreferrer" className={styles.externalLink}>
                {getLinkIcon(faive.hyperlink)}
                <span className={styles.linkText}>{truncateUrl(faive.hyperlink)}</span>
              </a>
            )}
          </div>
          <div className={styles.cardFooter}>
            <span className={styles.faiveCategory}>{faive.category}</span>
            <span className={styles.faiveDate}>{formatDate(faive.addedAt)}</span>
          </div>
          <p className={styles.faiveVisibility}>Visibility: {faive.visibility}</p>
          {isOwner && (
            <div className={styles.faiveActions}>
              <button onClick={() => setIsEditing(true)}>Edit</button>
              <button onClick={handleDelete}>Delete</button>
            </div>
          )}
          {error && <p className={styles.faiveError}>{error}</p>}
        </>
      )}
    </div>
  );
};

FaiveCard.propTypes = {
  faive: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    addedAt: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Date)]),
    hyperlink: PropTypes.string,
    visibility: PropTypes.string.isRequired,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

export default FaiveCard;