import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { auth } from '../firebase';
import { createFaive } from '../services/faiveService';
import styles from './FaiveForm.module.css';

const categories = [
  { name: 'Movie/TV', emoji: '🎬' },
  { name: 'Book', emoji: '📚' },
  { name: 'Music', emoji: '🎵' },
  { name: 'Food', emoji: '🍽️' },
  { name: 'Miscellaneous', emoji: '🌟' }
];

/**
 * @typedef {Object} FaiveData
 * @property {string} [id]
 * @property {string} title
 * @property {string} description
 * @property {string} category
 * @property {string} hyperlink
 * @property {('public'|'friends'|'private')} visibility
 */

/**
 * @param {Object} props
 * @param {function} props.onSuccess
 * @param {boolean} [props.compact]
 * @param {Partial<FaiveData>} [props.initialData]
 */
function FaiveForm({ onSuccess, compact = false, initialData = {} }) {
  const [title, setTitle] = useState(initialData.title || '');
  const [description, setDescription] = useState(initialData.description || '');
  const [category, setCategory] = useState(initialData.category || categories[0].name);
  const [hyperlink, setHyperlink] = useState(initialData.hyperlink || '');
  const [visibility, setVisibility] = useState(initialData.visibility || 'friends');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!auth.currentUser) {
      console.error("No authenticated user found.");
      return;
    }

    try {
      /** @type {FaiveData} */
      const faiveData = {
        title,
        description,
        category,
        hyperlink,
        visibility,
      };

      const faiveId = await createFaive(auth.currentUser.uid, faiveData);
      console.log("Faive added with ID:", faiveId);

      setTitle('');
      setDescription('');
      setCategory(categories[0].name);
      setHyperlink('');
      setVisibility('friends');

      onSuccess();
    } catch (error) {
      console.error("Error adding faive: ", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={compact ? styles.compactForm : styles.faiveForm}>
      <div className={styles.formGroup}>
        <label htmlFor="category">Category:</label>
        <select
          id="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className={styles.select}
        >
          {categories.map(cat => (
            <option key={cat.name} value={cat.name}>
              {cat.emoji} {cat.name}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="title">Title:</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter title"
          required
          className={styles.input}
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="description">Description:</label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter description"
          required
          className={styles.textarea}
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="hyperlink">Hyperlink:</label>
        <input
          type="url"
          id="hyperlink"
          value={hyperlink}
          onChange={(e) => setHyperlink(e.target.value)}
          placeholder="Enter a related URL"
          className={styles.input}
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="visibility">Visibility:</label>
        <select
          id="visibility"
          value={visibility}
          onChange={(e) => setVisibility(e.target.value)}
          className={styles.select}
        >
          <option value="public">Public</option>
          <option value="friends">Friends Only</option>
          <option value="private">Private</option>
        </select>
      </div>

      <button type="submit" className={styles.submitButton} disabled={!title || !description}>
        {initialData.id ? 'Update' : 'Create'} Faive
      </button>
    </form>
  );
}

FaiveForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  compact: PropTypes.bool,
  initialData: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    hyperlink: PropTypes.string,
    visibility: PropTypes.oneOf(['public', 'friends', 'private']),
  }),
};

export default FaiveForm;