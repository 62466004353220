import { collection, query, where, orderBy, limit, getDocs, startAfter } from 'firebase/firestore';
import { db } from '../firebase';
import { getFriends } from './relationshipService';

const ITEMS_PER_PAGE = 20;

export const getFeedItems = async (userId, limitCount = ITEMS_PER_PAGE, categories = [], lastVisible = null) => {
  try {
    const faivesRef = collection(db, 'faives');
    let friends = [];
    let queryConstraints = [];

    if (userId) {
      friends = await getFriends(userId);
    }

    // We'll fetch all public faives and faives from friends (if logged in)
    queryConstraints.push(
      where('visibility', 'in', ['public', 'friends']),
      orderBy('addedAt', 'desc'),
      limit(limitCount)
    );

    if (categories && categories.length > 0) {
      queryConstraints.unshift(where('category', 'in', categories));
    }

    if (lastVisible) {
      queryConstraints.push(startAfter(lastVisible));
    }

    const faivesQuery = query(faivesRef, ...queryConstraints);
    const querySnapshot = await getDocs(faivesQuery);

    const faives = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      addedAt: doc.data().addedAt.toDate()
    }));

    // Filter to include public faives, user's own faives, and friends' faives
    const filteredFaives = faives.filter(faive => 
      faive.visibility === 'public' ||
      (userId && (
        faive.userId === userId ||
        (friends.includes(faive.userId) && faive.visibility === 'friends')
      ))
    );

    const newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1] || null;

    return {
      faives: filteredFaives,
      lastVisible: newLastVisible,
      hasMore: querySnapshot.docs.length === limitCount
    };
  } catch (error) {
    console.error('Error fetching feed items:', error);
    throw error;
  }
};

export const getFeedItemsForUser = async (userId, limitCount = ITEMS_PER_PAGE, categories = []) => {
  try {
    const faivesRef = collection(db, 'faives');
    let queryConstraints = [
      where('userId', '==', userId),
      orderBy('addedAt', 'desc'),
      limit(limitCount)
    ];

    if (categories && categories.length > 0) {
      queryConstraints.unshift(where('category', 'in', categories));
    }

    const faivesQuery = query(faivesRef, ...queryConstraints);
    const querySnapshot = await getDocs(faivesQuery);

    const faives = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      addedAt: doc.data().addedAt.toDate()
    }));

    return faives;
  } catch (error) {
    console.error('Error fetching feed items for user:', error);
    throw error;
  }
};

export const getPublicFeedItems = async (limitCount = ITEMS_PER_PAGE, categories = [], lastVisible = null) => {
  try {
    const faivesRef = collection(db, 'faives');
    let queryConstraints = [
      where('visibility', '==', 'public'),
      orderBy('addedAt', 'desc'),
      limit(limitCount)
    ];

    if (categories && categories.length > 0) {
      queryConstraints.unshift(where('category', 'in', categories));
    }

    if (lastVisible) {
      queryConstraints.push(startAfter(lastVisible));
    }

    const faivesQuery = query(faivesRef, ...queryConstraints);
    const querySnapshot = await getDocs(faivesQuery);

    const faives = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      addedAt: doc.data().addedAt.toDate()
    }));

    const newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

    return {
      faives,
      lastVisible: newLastVisible,
      hasMore: querySnapshot.docs.length === limitCount
    };
  } catch (error) {
    console.error('Error fetching public feed items:', error);
    throw error;
  }
};