import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../context/AuthContext';
import styles from './BrowseUsers.module.css';
import { FaUser, FaUserPlus } from 'react-icons/fa';
import { sendFriendRequest, getFriends } from '../services/relationshipService';

const BrowseUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [friends, setFriends] = useState([]);
  const { currentUser } = useAuth();
  const pageSize = 20;

  useEffect(() => {
    fetchUsers();
    if (currentUser) {
      fetchFriends();
    }
  }, [currentUser]);

  const fetchFriends = async () => {
    try {
      const friendIds = await getFriends(currentUser.uid);
      setFriends(friendIds);
    } catch (error) {
      console.error('Error fetching friends:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const usersRef = collection(db, 'users');
      const q = query(usersRef, orderBy('username'), limit(pageSize));
      const querySnapshot = await getDocs(q);

      const fetchedUsers = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setUsers(fetchedUsers);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === pageSize);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to load users. Please try again later.');
      setLoading(false);
    }
  };

  const fetchMoreUsers = async () => {
    if (!hasMore) return;

    try {
      setLoading(true);
      const usersRef = collection(db, 'users');
      const q = query(usersRef, orderBy('username'), startAfter(lastVisible), limit(pageSize));
      const querySnapshot = await getDocs(q);

      const fetchedUsers = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setUsers(prevUsers => [...prevUsers, ...fetchedUsers]);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === pageSize);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching more users:', err);
      setError('Failed to load more users. Please try again.');
      setLoading(false);
    }
  };

  const handleSendFriendRequest = async (userId) => {
    try {
      await sendFriendRequest(currentUser.uid, userId);
      // Update the local state to reflect the sent request
      setUsers(prevUsers =>
        prevUsers.map(user =>
          user.id === userId ? { ...user, requestSent: true } : user
        )
      );
    } catch (error) {
      console.error('Error sending friend request:', error);
      setError('Failed to send friend request. Please try again.');
    }
  };

  return (
    <div className={styles.browseUsersContainer}>
      <h2 className={styles.title}>Discover New Friends</h2>
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.userGrid}>
        {users.map(user => (
          <div key={user.id} className={styles.userCard}>
            <Link to={`/profile/${user.username}`} className={styles.userLink}>
              {user.photoURL ? (
                <img src={user.photoURL} alt={user.username} className={styles.userPhoto} />
              ) : (
                <div className={styles.userPhotoPlaceholder}>
                  <FaUser />
                </div>
              )}
              <h3 className={styles.username}>{user.username}</h3>
            </Link>
            <p className={styles.userBio}>{user.bio || 'No bio available'}</p>
            {currentUser && currentUser.uid !== user.id && !friends.includes(user.id) && (
              <button
                onClick={() => handleSendFriendRequest(user.id)}
                className={styles.addFriendButton}
                disabled={user.requestSent}
              >
                <FaUserPlus /> {user.requestSent ? 'Request Sent' : 'Add Friend'}
              </button>
            )}
          </div>
        ))}
      </div>
      {hasMore && (
        <button onClick={fetchMoreUsers} className={styles.loadMoreButton} disabled={loading}>
          {loading ? 'Loading...' : 'Load More'}
        </button>
      )}
    </div>
  );
};

export default BrowseUsers;