// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import HomePage from './pages/HomePage';
import UserProfilePage from './pages/UserProfilePage';
import CreateFaivePage from './pages/CreateFaivePage';
import FriendsPage from './pages/FriendsPage';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import AboutPage from './pages/AboutPage';
import FeedbackPage from './pages/FeedbackPage';
import Navigation from './components/Navigation';
import ProtectedRoute from './components/ProtectedRoute';
import WelcomeScreen from './components/WelcomeScreen';
import Onboarding from './components/Onboarding';
import styles from './App.module.css';

function AppContent() {
  const { currentUser, onboardingCompleted, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // Or a proper loading component
  }

  return (
    <Router>
      <div className={styles.app}>
        <Navigation user={currentUser} />
        <main className={styles.main}>
          <Routes>
            <Route 
              path="/" 
              element={
                currentUser 
                  ? (onboardingCompleted ? <HomePage /> : <Navigate to="/onboarding" />)
                  : <WelcomeScreen />
              } 
            />
            <Route 
              path="/onboarding" 
              element={
                currentUser
                  ? (onboardingCompleted ? <Navigate to="/" /> : <Onboarding />)
                  : <Navigate to="/signin" />
              } 
            />
            <Route path="/profile" element={<ProtectedRoute element={<UserProfilePage />} />} />
            <Route path="/profile/:username" element={<UserProfilePage />} />
            <Route path="/create" element={<ProtectedRoute element={<CreateFaivePage />} />} />
            <Route path="/friends" element={<ProtectedRoute element={<FriendsPage />} />} />
            <Route path="/signup" element={currentUser ? <Navigate to="/" /> : <SignUp />} />
            <Route path="/signin" element={currentUser ? <Navigate to="/" /> : <SignIn />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/feedback" element={<FeedbackPage />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;