import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { logger } from './utils/logger';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const requiredKeys = ['apiKey', 'authDomain', 'projectId', 'storageBucket', 'appId'];

let app;
let auth;
let db;
let storage;

try {
  const missingKeys = requiredKeys.filter(key => !firebaseConfig[key]);
  
  if (missingKeys.length > 0) {
    throw new Error(`Firebase configuration is missing: ${missingKeys.join(', ')}. Check your .env file.`);
  }

  if (getApps().length === 0) {
    app = initializeApp(firebaseConfig);
    logger.log('Firebase initialized successfully');
  } else {
    app = getApp();
    logger.log('Firebase app already initialized');
  }

  auth = getAuth(app);
  db = getFirestore(app);
  storage = getStorage(app);
  
  logger.log('Firebase auth, Firestore, and Storage initialized');

  // Only log in development, and don't log the entire config
  if (process.env.NODE_ENV === 'development') {
    console.log('Firebase initialized with project:', firebaseConfig.projectId);
  }
} catch (error) {
  logger.error('Error initializing Firebase:', error.message);
  app = null;
  auth = null;
  db = null;
  storage = null;
}

export { app, auth, db, storage };