import React, { useState, useCallback } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, storage } from '../firebase';
import { createUserProfile } from '../services/userService';
import { useNavigate, Link } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useDropzone } from 'react-dropzone';
import { useAuth } from '../context/AuthContext';
import styles from './SignUp.module.css';
import { FaUser, FaEnvelope, FaLock, FaImage } from 'react-icons/fa';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [bio, setBio] = useState('');
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { refreshUser } = useAuth();

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      let photoURL = '';
      if (file) {
        const storageRef = ref(storage, `profile_photos/${user.uid}`);
        await uploadBytes(storageRef, file);
        photoURL = await getDownloadURL(storageRef);
      }

      await createUserProfile(user.uid, {
        username,
        email,
        bio,
        photoURL,
        onboardingCompleted: false
      });

      await refreshUser();
      console.log('User registered successfully');
      navigate('/onboarding');
    } catch (error) {
      setError(error.message);
      console.error('Error registering user:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.signUpContainer}>
        <h2 className={styles.title}>Join Faives</h2>
        <form onSubmit={handleSubmit} className={styles.signUpForm}>
          <div className={styles.inputGroup}>
            <FaUser className={styles.inputIcon} />
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              required
            />
          </div>
          <div className={styles.inputGroup}>
            <FaEnvelope className={styles.inputIcon} />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
          </div>
          <div className={styles.inputGroup}>
            <FaLock className={styles.inputIcon} />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
          </div>
          <textarea
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            placeholder="Tell us about yourself"
            rows={4}
            className={styles.bioInput}
          />
          <div {...getRootProps()} className={styles.dropzone}>
            <input {...getInputProps()} />
            <FaImage className={styles.dropzoneIcon} />
            <p>{file ? file.name : "Drag 'n' drop a profile photo or click to select"}</p>
          </div>
          <button type="submit" disabled={loading} className={styles.submitButton}>
            {loading ? 'Signing up...' : 'Sign Up'}
          </button>
        </form>
        {error && <p className={styles.error}>{error}</p>}
        <p className={styles.signInPrompt}>
          Already have an account? <Link to="/signin" className={styles.link}>Sign In</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUp;