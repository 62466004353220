import { doc, setDoc, updateDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

export const createUserProfile = async (userId, userData) => {
  try {
    const userRef = doc(db, 'users', userId);
    await setDoc(userRef, {
      uid: userId,
      username: userData.username,
      email: userData.email,
      bio: userData.bio || '',
      photoURL: userData.photoURL || '',
      createdAt: new Date(),
      updatedAt: new Date(),
      version: "1.0",
      visibility: "public",
      socialLinks: {},
      additionalData: {}
    });
    console.log("User profile created successfully");
  } catch (error) {
    console.error("Error creating user profile: ", error);
    throw error;
  }
};

export const updateOnboardingStatus = async (userId, status) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      onboardingCompleted: status,
      updatedAt: new Date()
    });
    console.log("Onboarding status updated successfully");
  } catch (error) {
    console.error("Error updating onboarding status: ", error);
    throw error;
  }
};


export const getUserByUsername = async (username) => {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('username', '==', username));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      console.log("No user found with username:", username);
      return null;
    }
    const userDoc = querySnapshot.docs[0];
    const userData = { uid: userDoc.id, ...userDoc.data() };
    console.log("User data fetched by username:", userData);
    return userData;
  } catch (error) {
    console.error("Error getting user by username: ", error);
    throw error;
  }
};

export const getUserIdFromEmailOrUsername = async (searchTerm) => {
  try {
    const usersRef = collection(db, 'users');
    const emailQuery = query(usersRef, where('email', '==', searchTerm.toLowerCase()));
    const usernameQuery = query(usersRef, where('username', '==', searchTerm));

    const [emailSnapshot, usernameSnapshot] = await Promise.all([
      getDocs(emailQuery),
      getDocs(usernameQuery)
    ]);

    if (!emailSnapshot.empty) {
      return emailSnapshot.docs[0].id;
    }

    if (!usernameSnapshot.empty) {
      return usernameSnapshot.docs[0].id;
    }

    // If no exact match, try case-insensitive username search
    const lowercaseSearchTerm = searchTerm.toLowerCase();
    const allUsersSnapshot = await getDocs(collection(db, 'users'));
    const matchingUser = allUsersSnapshot.docs.find(doc => 
      doc.data().username.toLowerCase() === lowercaseSearchTerm
    );

    if (matchingUser) {
      return matchingUser.id;
    }

    throw new Error('No user found with this email or username');
  } catch (error) {
    console.error("Error getting user ID from email or username: ", error);
    throw error;
  }
};

export const updateUserProfile = async (userId, updateData) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      ...updateData,
      updatedAt: new Date()
    });
    console.log("User profile updated successfully");
  } catch (error) {
    console.error("Error updating user profile: ", error);
    throw error;
  }
};

export const getUserProfile = async (userId) => {
  if (typeof userId !== 'string' || userId.trim() === '') {
    console.error("Invalid userId provided to getUserProfile:", userId);
    throw new Error("Invalid userId");
  }
  try {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      const userData = userSnap.data();
      return { 
        uid: userSnap.id, 
        bio: userData.bio || '',
        ...userData,
        onboardingCompleted: userData.onboardingCompleted || false 
      };
    }
    console.log("No user found for ID:", userId);
    return null;
  } catch (error) {
    console.error("Error getting user profile: ", error);
    throw error;
  }
};