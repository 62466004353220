import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { getFeedItems } from '../services/feedService';
import { getUserProfile } from '../services/userService';
import FaiveCard from './FaiveCard';
import styles from './Feed.module.css';

const categories = [
  { name: 'Movie/TV', emoji: '🎬' },
  { name: 'Book', emoji: '📚' },
  { name: 'Music', emoji: '🎵' },
  { name: 'Food', emoji: '🍽️' },
  { name: 'Miscellaneous', emoji: '✨' }
];

const FeedComponent = () => {
  const [feedItems, setFeedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [usernames, setUsernames] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const fetchFeedItems = async (isInitialFetch = false) => {
    try {
      setLoading(true);
      const userId = auth.currentUser ? auth.currentUser.uid : null;
      const { faives, lastVisible: newLastVisible, hasMore: newHasMore } = await getFeedItems(
        userId, 
        20, 
        selectedCategories.map(cat => cat.name), 
        isInitialFetch ? null : lastVisible
      );
      
      setFeedItems(prevItems => isInitialFetch ? faives : [...prevItems, ...faives]);
      setLastVisible(newLastVisible);
      setHasMore(newHasMore);

      // Fetch usernames for all unique user IDs
      const uniqueUserIds = [...new Set(faives.map(item => item.userId).filter(Boolean))];
      const usernamePromises = uniqueUserIds.map(async (uid) => {
        try {
          const userProfile = await getUserProfile(uid);
          return userProfile ? { [uid]: userProfile.username } : { [uid]: 'Unknown User' };
        } catch (error) {
          console.error(`Error fetching username for user ${uid}:`, error);
          return { [uid]: 'Unknown User' };
        }
      });
      const usernameResults = await Promise.all(usernamePromises);
      const usernameMap = Object.assign({}, ...usernameResults);
      setUsernames(prevUsernames => ({ ...prevUsernames, ...usernameMap }));
      
      setError(null);
    } catch (err) {
      console.error('Error fetching feed items:', err);
      setError(err.message || 'Failed to load feed items. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeedItems(true);
  }, [selectedCategories]);

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev => {
      const newCategories = prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category];
      
      // Force re-render by updating a state
      setLastVisible(null);
      setHasMore(true);
      
      return newCategories;
    });
  };

  const loadMore = () => {
    if (!loading && hasMore) {
      fetchFeedItems();
    }
  };

  if (error) {
    return <div className={styles.errorContainer}>Error: {error}</div>;
  }

  return (
    <div className={styles.feedContainer}>
      <div className={styles.feedHeader}>
        <h2 className={styles.feedTitle}>Your Feed</h2>
        <div className={styles.categoryFilters}>
          {categories.map(category => (
            <button
              key={category.name}
              onClick={() => handleCategoryChange(category)}
              className={`${styles.categoryFilter} ${selectedCategories.includes(category) ? styles.active : ''}`}
              aria-label={`Filter by ${category.name}`}
            >
              <span className={styles.categoryEmoji}>{category.emoji}</span>
              <span className={styles.categoryName}>{category.name}</span>
            </button>
          ))}
        </div>
      </div>
      {feedItems.length === 0 ? (
        <p className={styles.emptyFeedMessage}>No items in your feed yet. Try adding some friends or creating some faives!</p>
      ) : (
        <div className={styles.faiveGrid}>
          {feedItems.map(item => (
            <FaiveCard
              key={item.id}
              faive={item}
              onUpdate={() => fetchFeedItems(true)}
              username={item.userId && usernames[item.userId] ? usernames[item.userId] : 'Unknown User'}
            />
          ))}
        </div>
      )}
      {loading && <div className={styles.loadingContainer}>Loading more items...</div>}
      {!loading && hasMore && (
        <button onClick={loadMore} className={styles.loadMoreButton}>
          Load More
        </button>
      )}
    </div>
  );
};

export default FeedComponent;