import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../components/Container';
import FaiveForm from '../components/FaiveForm';
import styles from './CreateFaivePage.module.css';

function CreateFaivePage() {
  const navigate = useNavigate();

  const handleFaiveAdded = () => {
    console.log('Faive created successfully!');
    navigate('/'); // Redirect to home page after creation
  };

  return (
    <div className={styles.createFaivePage}>
      <Container>
        <h1 className={styles.pageTitle}>Create New Faive</h1>
        <FaiveForm onSuccess={handleFaiveAdded} />
      </Container>
    </div>
  );
}

export default CreateFaivePage;