import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { updateOnboardingStatus } from '../services/userService';
import BrowseUsers from './BrowseUsers';
import FaiveForm from './FaiveForm';
import { getFaives } from '../services/faiveService';
import styles from './Onboarding.module.css';

const Onboarding = () => {
  const [step, setStep] = useState(1);
  const [faives, setFaives] = useState([]);
  const { currentUser, refreshUser } = useAuth();
  const navigate = useNavigate();
  const totalSteps = 2; // Reduced to 2 steps

  useEffect(() => {
    const fetchFaives = async () => {
      if (currentUser) {
        const userFaives = await getFaives(currentUser.uid);
        setFaives(userFaives);
      }
    };
    fetchFaives();
  }, [currentUser]);

  const handleNext = async () => {
    if (step < totalSteps) {
      setStep(step + 1);
    } else {
      await handleFinish();
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleFaiveAdded = async () => {
    const updatedFaives = await getFaives(currentUser.uid);
    setFaives(updatedFaives);
  };

  const handleFinish = async () => {
    try {
      await updateOnboardingStatus(currentUser.uid, true);
      await refreshUser();
      navigate('/', { replace: true });
    } catch (error) {
      console.error("Error completing onboarding:", error);
    }
  };

  return (
    <div className={styles.onboarding}>
      <h1 className={styles.title}>Welcome to Faives!</h1>
      <div className={styles.navigation}>
        {Array.from({ length: totalSteps }, (_, i) => (
          <div
            key={i}
            className={`${styles.step} ${i + 1 === step ? styles.active : ''} ${i + 1 < step ? styles.completed : ''}`}
            onClick={() => setStep(i + 1)}
          >
            {i + 1}
          </div>
        ))}
      </div>
      {step === 1 && (
        <div className={styles.stepContent}>
          <h2>Step 1: Find Friends</h2>
          <p>Connect with friends to see their Faives and share yours!</p>
          <BrowseUsers />
        </div>
      )}
      {step === 2 && (
        <div className={styles.stepContent}>
          <h2>Step 2: Add Your First Faives</h2>
          <p>Share your favorite things with your friends!</p>
          <FaiveForm onSuccess={handleFaiveAdded} />
        </div>
      )}
      <div className={styles.buttonContainer}>
        {step > 1 && (
          <button onClick={handleBack} className={styles.backButton}>
            Back
          </button>
        )}
        <button
          onClick={handleNext}
          className={styles.nextButton}
          disabled={step === 2 && faives.length === 0}
        >
          {step === totalSteps ? 'Finish' : 'Next'}
        </button>
      </div>
    </div>
  );
};

export default Onboarding;