import { collection, addDoc, doc, updateDoc, setDoc, deleteDoc, getDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { createActivity } from './activityService';

export const createFaive = async (userId, faiveData) => {
  try {
    console.log("Starting to create Faive for user:", userId);
    
    const faiveRef = await addDoc(collection(db, 'faives'), {
      userId: userId,
      category: faiveData.category,
      title: faiveData.title,
      description: faiveData.description,
      hyperlink: faiveData.hyperlink,
      addedAt: new Date(),
      updatedAt: new Date(),
      version: "1.0",
      visibility: faiveData.visibility || 'friends',
      customFields: faiveData.customFields || {},
      additionalData: {}
    });

    console.log("Faive added to main 'faives' collection with ID:", faiveRef.id);

    try {
      await setDoc(doc(db, 'users', userId, 'faives', faiveRef.id), {
        addedAt: new Date()
      });
      console.log("Faive reference added to user's subcollection");
    } catch (subError) {
      console.error("Error updating userFaives subcollection:", subError);
    }

    // Create activity for the new faive
    await createActivity(userId, 'created', faiveData.category, faiveRef.id);

    console.log("Faive creation process completed successfully");
    return faiveRef.id;
  } catch (error) {
    console.error("Error creating faive: ", error);
    throw error;
  }
};

export const getFaive = async (faiveId) => {
  try {
    const faiveDoc = await getDoc(doc(db, 'faives', faiveId));
    if (faiveDoc.exists()) {
      return { id: faiveDoc.id, ...faiveDoc.data() };
    } else {
      console.log("No such Faive!");
      return null;
    }
  } catch (error) {
    console.error("Error getting faive: ", error);
    throw error;
  }
};

export const getFaives = async (userId) => {
  try {
    const faivesRef = collection(db, 'faives');
    const q = query(faivesRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error getting faives for user:", error);
    throw error;
  }
};


export const getFaivesWithUserData = async (userId) => {
  try {
    const faivesRef = collection(db, 'faives');
    const q = query(faivesRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const faives = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
    // Fetch user data for each faive
    const faivesWithUserData = await Promise.all(faives.map(async (faive) => {
      // Remove the type assertion, use the faive object directly
      const userDoc = await getDoc(doc(db, 'users', faive.userId));
      const userData = userDoc.data();
      return {
        ...faive,
        userPhotoURL: userData?.photoURL || null,
      };
    }));
    
    return faivesWithUserData;
  } catch (error) {
    console.error("Error getting faives with user data:", error);
    throw error;
  }
};

export const updateFaive = async (faiveId, faiveData) => {
  try {
    const faiveRef = doc(db, 'faives', faiveId);
    await updateDoc(faiveRef, {
      ...faiveData,
      updatedAt: new Date()
    });
    console.log("Faive updated successfully");
  } catch (error) {
    console.error("Error updating faive: ", error);
    throw error;
  }
};

export const updateFaiveVisibility = async (faiveId, visibility) => {
  return updateFaive(faiveId, { visibility });
};

export const deleteFaive = async (faiveId) => {
  try {
    await deleteDoc(doc(db, 'faives', faiveId));
    console.log("Faive deleted successfully");
  } catch (error) {
    console.error("Error deleting faive: ", error);
    throw error;
  }
};

export const removeFromUserFaives = async (userId, faiveId) => {
  try {
    await deleteDoc(doc(db, 'users', userId, 'faives', faiveId));
    console.log("Faive removed from userFaives successfully");
  } catch (error) {
    console.error("Error removing faive from userFaives: ", error);
    throw error;
  }
};

export const deleteFaiveCompletely = async (userId, faiveId) => {
  try {
    await deleteFaive(faiveId);
    console.log("Faive deleted successfully");
  } catch (error) {
    console.error("Error deleting faive:", error);
    throw error;
  }

  try {
    await removeFromUserFaives(userId, faiveId);
    console.log("Faive removed from userFaives successfully");
  } catch (error) {
    console.error("Error removing faive from userFaives:", error);
  }
};