import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { getUserProfile } from '../services/userService';

const AuthContext = createContext({ currentUser: null, onboardingCompleted: false, refreshUser: () => {} });

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const [loading, setLoading] = useState(true);

  const refreshUser = useCallback(async (user) => {
    if (user) {
      const userProfile = await getUserProfile(user.uid);
      setOnboardingCompleted(userProfile.onboardingCompleted || false);
    } else {
      setOnboardingCompleted(false);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      await refreshUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, [refreshUser]);

  const value = {
    currentUser,
    onboardingCompleted,
    refreshUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};