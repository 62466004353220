import React from 'react';
import { Link } from 'react-router-dom';
import styles from './WelcomeScreen.module.css';
import logo from '../assets/logo192.png';

const categories = [
  { name: 'Movie/TV', emoji: '🎬' },
  { name: 'Book', emoji: '📚' },
  { name: 'Music', emoji: '🎵' },
  { name: 'Food', emoji: '🍽️' },
  { name: 'Miscellaneous', emoji: '🌟' }
];

const WelcomeScreen = () => {
  return (
    <div className={styles.welcomeContainer}>
      <div className={styles.content}>
        <img src={logo} alt="Faives Logo" className={styles.logo} />
        <h1 className={styles.title}>Welcome to Faives</h1>
        <p className={styles.tagline}>Share Your Top 5 Favorites, Discover Trusted Recommendations</p>
        <div className={styles.categoryList}>
          {categories.map((category, index) => (
            <span key={index} className={styles.category}>
              {category.emoji} {category.name}
            </span>
          ))}
        </div>
        <p className={styles.description}>
          Faives is a space to share and find awesome stuff across 5 categories: tv/movies, books, food, music, and miscellaneous. 
          Connect with friends, discover authentic recommendations, and celebrate what you love.
        </p>
        <div className={styles.ctaContainer}>
          <Link to="/signup" className={`${styles.ctaButton} ${styles.signupButton}`}>Get Started</Link>
          <Link to="/signin" className={`${styles.ctaButton} ${styles.loginButton}`}>Log In</Link>
        </div>
        <Link to="/about" className={styles.learnMoreButton}>Learn More About Faives</Link>
      </div>
    </div>
  );
};

export default WelcomeScreen;