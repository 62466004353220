import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AboutPage.module.css';

const categories = [
  { name: 'Movie/TV', emoji: '🎬' },
  { name: 'Book', emoji: '📚' },
  { name: 'Music', emoji: '🎵' },
  { name: 'Food', emoji: '🍽️' },
  { name: 'Miscellaneous', emoji: '🌟' }
];

const AboutPage = () => {
  return (
    <div className={styles.aboutContainer}>
      <h1 className={styles.title}>About Faives</h1>
      <p className={styles.description}>
        Faives is a refreshing take on recommendations in an age when algorithm-driven social feeds leave us feeling less connected. 
        We're bringing back the personal touch of friend-to-friend suggestions, moving away from algorithm-driven content "curation" that dominates our feeds and our lives today.
      </p>
      <div className={styles.categoryList}>
        {categories.map((category, index) => (
          <span key={index} className={styles.category}>
            {category.emoji} {category.name}
          </span>
        ))}
      </div>
      <h2 className={styles.sectionTitle}>How It Works</h2>
      <ul className={styles.featureList}>
        <li>Share your top 5 recommendations across various categories like movies, TV shows, books, music, and food.</li>
        <li>Discover great content through people you know and trust, not complex algorithms or sponsored content.</li>
        <li>Enjoy a clean, ad-free interface that focuses on what matters: your favorites and those of your friends.</li>
        <li>Experience the joy of discovery through personal, trustworthy recommendations.</li>
      </ul>
      <h2 className={styles.sectionTitle}>Our Philosophy</h2>
      <p className={styles.description}>
        At Faives, we believe in quality over quantity, personal connections over algorithms, and mindful curation over endless scrolling. We're here to make the process of finding new things to enjoy more intimate, trustworthy, and ultimately more satisfying.
      </p>
      <div className={styles.ctaContainer}>
        <Link to="/signup" className={styles.ctaButton}>Join Faives Today</Link>
        <Link to="/" className={styles.backLink}>Back to Home</Link>
      </div>
    </div>
  );
};

export default AboutPage;