import React from 'react';
import FeedbackForm from '../components/FeedbackForm';
import styles from './FeedbackPage.module.css';

const FeedbackPage = () => {
  return (
    <div className={styles.feedbackPage}>
      <h1 className={styles.title}>We Value Your Feedback</h1>
      <p className={styles.description}>
        Your input is crucial in helping us improve Faives. Whether you have suggestions, 
        encountered issues, or just want to share your thoughts, we're all ears!
      </p>
      <FeedbackForm />
      <div className={styles.additionalInfo}>
        <h2>Why Your Feedback Matters</h2>
        <ul>
          <li>It helps us identify and fix issues quickly</li>
          <li>Your ideas could shape the future features of Faives</li>
          <li>It ensures we're meeting the needs of our community</li>
        </ul>
      </div>
    </div>
  );
};

export default FeedbackPage;