// src/pages/HomePage.js
import React from 'react';
import FeedComponent from '../components/FeedComponent';
import FriendActivity from 'components/FriendActivity'
import styles from './HomePage.module.css';

function HomePage() {
  return (
    <div className={styles.homePage}>
      <div className={styles.mainContent}>
        <FeedComponent />
      </div>
      <div className={styles.sidebar}>
        <FriendActivity />
      </div>
    </div>
  );
}

export default HomePage;