import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { getFriendRequests } from '../services/relationshipService';
import { FaHome, FaUser, FaPlusCircle, FaUsers, FaBars, FaSignOutAlt, FaCommentAlt } from 'react-icons/fa';
import styles from './Navigation.module.css';
import logo from '../assets/logo192.png';

const Navigation = ({ user }) => {
  const [friendRequestCount, setFriendRequestCount] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  useEffect(() => {
    if (user) {
      const fetchFriendRequests = async () => {
        try {
          const requests = await getFriendRequests(user.uid);
          setFriendRequestCount(requests.length);
        } catch (error) {
          console.error('Error fetching friend requests:', error);
        }
      };
      fetchFriendRequests();
    }
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log('User signed out successfully');
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav className={styles.navigation}>
      <div className={styles.navContent}>
        <Link to="/" className={styles.logoContainer}>
          <img src={logo} alt="Faives Logo" className={styles.logo} />
          <span className={styles.logoText}>Faives</span>
        </Link>
        {user ? (
          <>
            <button onClick={toggleMenu} className={styles.menuButton}>
              <FaBars />
            </button>
            <div ref={menuRef} className={`${styles.navLinks} ${showMenu ? styles.showMenu : ''}`}>
              <Link to="/" className={styles.navLink} onClick={() => setShowMenu(false)}>
                <FaHome /> <span>Home</span>
              </Link>
              <Link to="/profile" className={styles.navLink} onClick={() => setShowMenu(false)}>
                <FaUser /> <span>Profile</span>
              </Link>
              <Link to="/create" className={styles.navLink} onClick={() => setShowMenu(false)}>
                <FaPlusCircle /> <span>Create Faive</span>
              </Link>
              <Link to="/friends" className={styles.navLink} onClick={() => setShowMenu(false)}>
                <FaUsers /> <span>Friends</span>
                {friendRequestCount > 0 && (
                  <span className={styles.notificationBadge}>{friendRequestCount}</span>
                )}
              </Link>
              <Link to="/feedback" className={styles.navLink} onClick={() => setShowMenu(false)}>
              <FaCommentAlt/> <span>Feedback</span>
          </Link>
              <button onClick={handleSignOut} className={styles.navLink}>
                <FaSignOutAlt /> <span>Sign Out</span>
              </button>
            </div>
          </>
        ) : (
          <div className={styles.authLinks}>
            <Link to="/signin" className={styles.signInLink}>Sign In</Link>
            <Link to="/signup" className={styles.signUpButton}>Sign Up</Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navigation;